<template>
    <div id="inc_header">
        <header>
            <div class="part1">
                <div class="container">
                    <img src="../assets/image/logo.png">
                </div>
            </div>
            <div class="part2">
                <nav class="container">
                    <el-menu mode="horizontal" router>
                        <el-menu-item index="/">首页 <span>Home</span></el-menu-item>
                        <el-menu-item index="/event">资讯 <span>Events</span></el-menu-item>
                        <el-submenu index="3" popper-class="m3">
                            <template slot="title">产品 <span>Products</span></template>
                            <el-menu-item index="/dance_wear">舞蹈服装</el-menu-item>
                            <el-menu-item index="/photography">芭蕾摄影</el-menu-item>
                        </el-submenu>
                        <el-submenu index="4" popper-class="m4">
                            <template slot="title">培训 <span>Education</span></template>
                            <el-menu-item index="/about_mitone">关于觅堂</el-menu-item>
                            <el-menu-item index="/course_arrangement">课程安排</el-menu-item>
                            <el-menu-item index="/our_teachers">师资团队</el-menu-item>
                            <el-menu-item index="/class_story">课堂花絮</el-menu-item>
                        </el-submenu>
                        <el-menu-item><a href="https://tieba.baidu.com/f?kw=%E8%A7%85%E4%B8%B9%E6%80%9D" target="_blank">论坛 <span>Forum</span></a></el-menu-item>
                        <el-menu-item index="/about_us">关于我们 <span>About Us</span></el-menu-item>
                    </el-menu>
                </nav>
            </div>
        </header>
    </div>
</template>

<style>
    header { width:100%; height:auto; }
    header .part1 { width:100%; height:120px; background:#333; }
    header .part1 div { position:relative; overflow:visible; }
    header .part1 div img { position:absolute; left:0; top:10px; border:none; z-index:9; }
    header .part2 { width:100%; height:60px; background:#fcc; }
    header .part2 nav { padding:0 0 0 75px; }
    header .part2 nav .el-menu { border:none; background-color:transparent; }
    header .part2 nav .el-menu-item { padding:0 25px; border:none; font-size:18px; color:#000; background-color:transparent; z-index:99; }
    header .part2 nav .el-menu-item:hover { background-color:#fff; }
    header .part2 nav .el-menu-item span { color:#a00e15; }
    header .part2 nav .el-menu-item a { display:block; }
    header .part2 nav .el-menu--horizontal>.el-submenu { border:none; background-color:transparent; }
    header .part2 nav .el-menu--horizontal>.el-submenu.is-opened { background-color:#fff; }
    header .part2 nav .el-menu--horizontal>.el-submenu .el-submenu__title { padding:0 25px; font-size:18px; color:#000; z-index:99; }
    header .part2 nav .el-menu--horizontal>.el-submenu .el-submenu__title span { color:#a00e15; }
    header .part2 nav .el-menu--horizontal>.el-submenu .el-submenu__title .el-submenu__icon-arrow { font-size:14px; }
    .el-menu--popup { min-width:100px; margin-top:0; padding:10px 20px 15px 20px; border-radius:0; box-shadow:0 10px 12px 0 rgba(0, 0, 0, 0.1); }
    .el-menu--popup .el-menu-item { width:100%; height:40px; display:block; line-height:40px; border-top:1px solid #fcc; font-size:16px; color:#000; text-align:center; text-decoration:none; }
    .el-menu--popup .el-menu-item:hover { color:#a00e15; }
</style>

<script>
    export default {
        name: "inc_header",
        mounted() {
            let that = this;
            let i=0;
            let timer = setInterval(function() {
                that.subMenuInit();
                i++;
                if(i == 10){
                    clearInterval(timer);
                }
            },500);
        },
        methods: {
            subMenuInit() {
                // console.log(window.getComputedStyle(document.querySelector(".el-menu .el-submenu:nth-child(3)"),null).getPropertyValue("width"));
                document.querySelector(".m3").style.minWidth = window.getComputedStyle(document.querySelector(".el-menu .el-submenu:nth-child(3)"), null).getPropertyValue("width");
                // console.log(document.querySelector(".m3").style.minWidth);

                // console.log(window.getComputedStyle(document.querySelector(".el-menu .el-submenu:nth-child(4)"), null).getPropertyValue("width"));
                document.querySelector(".m4").style.minWidth = window.getComputedStyle(document.querySelector(".el-menu .el-submenu:nth-child(4)"), null).getPropertyValue("width");
                // console.log(document.querySelector(".m4").style.minWidth);
            }
        }
    }
</script>
