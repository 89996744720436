<template>
    <div id="inc_footer">
        <footer>
            <div class="container">
                <img src="../assets/image/footer-qrcode.jpg">
                版权所有：北京觅丹思文化发展有限公司
                <br>
                地址：北京海淀区西三环中路华宝大厦
                <br>
                电话：010-68665668
            </div>
        </footer>
    </div>
</template>

<style scoped>
    footer { width:100%; height:auto; padding:44px 0 66px 0; color:#999; background:#333; }
    footer img { margin:0 25px 0 0; float:left; border:none; }
    footer .nav { margin-bottom:15px; }
    header .nav:after { height:0; display:block; content:''; clear:both; visibility:hidden; }
    footer .nav a { padding:0 15px; border-left:1px solid #999; color:#999; text-decoration:none; }
    footer .nav a:first-child { padding-left:0; border-left:none; }
    footer .nav a:hover { text-decoration:underline; }
</style>

<script>
    export default {
        name: "inc_footer"
    }
</script>
